import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import styles from "./termcondition.module.css";
import AdBar from "../Components/AdsBar";
export default function TermsCondition() {
  useEffect(() => {
    document.title = "Spectrum Trusted Reseller | Terms & Condition";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <AdBar />
      <NavBar />

      <div className={styles.outer}>
        <div className={styles.containter}>
          <div className={styles.backImges}></div>
          <div>
            <h1 className={styles.heading}>Terms of Service</h1>
            <p className={styles.Text}>
              Welcome to Spectrum Trusted Reseller These Terms of Service
              (“Terms”) govern your use of the services provided by Spectrum
              Trusted Reseller (“we,” “us,” or “our”). By accessing or using our
              services, you agree to be bound by these Terms. If you do not
              agree with any part of these Terms, you must not use our services.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Acceptance of Terms</h2>
            <p className={styles.Text}>
              By accessing and using the services offered by Spectrum Trusted
              Reseller, you confirm that you have read, understood, and agree to
              be bound by these Terms, as well as our Privacy Policy.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Description of Services</h2>
            <p className={styles.Text}>
              Spectrum Trusted Reseller provides internet connectivity and
              related services to individuals and businesses. The specifics of
              your plan, including speed, data limits, and pricing, are outlined
              in your service agreement.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Eligibility</h2>
            <p className={styles.Text}>
              To use our services, you may be required to create an account. You
              agree to provide accurate, current, and complete information
              during the registration process and to update such information to
              keep it accurate, current, and complete. You are responsible for
              maintaining the confidentiality of your account password and for
              all activities that occur under your account.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Account Registration</h2>
            <p className={styles.Text}>
              We employ robust security protocols to guard your data, though we
              acknowledge that absolute online security is unattainable. Your
              Options
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>User Responsibilities</h2>
            <p className={styles.Text}>
              You agree to use our services for lawful purposes only and comply
              with all applicable laws and regulations. You shall not:
              <br />
              Use the services in any manner that could damage, disable,
              overburden, or impair any of our servers or networks
              <br />
              Attempt to gain unauthorized access to any part of our services or
              accounts.
              <br />
              Use the services to infringe upon the rights of others, including
              intellectual property rights. Engage in any activity that
              interferes with or disrupts the services.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Payment and Billing</h2>
            <p style={{ marginBottom: "40px" }} className={styles.Text}>
              You agree to pay all charges for the services you subscribe to,
              including applicable taxes and fees. Payment is due on the dates
              specified in your service agreement. Failure to make payments on
              time may result in suspension or termination of your services.
              Modifications to Services and Terms We reserve the right to modify
              or discontinue, temporarily or permanently, our services (or any
              part thereof) with or without notice. We also reserve the right to
              modify these Terms at any time. Any changes will be effective
              immediately upon posting on our website. Your continued use of the
              services after any such changes constitute your acceptance of the
              new Terms.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
