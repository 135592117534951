import React from "react";
import style from "./adbar.module.css";
import { IoCallSharp } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";

export default function AdBar() {
  return (
    <div className={style.outer}>
      <div className={style.containter}>
        <p className={style.logo}>Spectrum Trusted Reseller</p>
        <div className={style.flx}>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div className={style.box}>
              <IoCallSharp className={style.icon} />
            </div>

            <div>
              <p className={style.heading1}>CALL US 24/7</p>
              <a
                style={{ textDecoration: "none" }}
                href="tel:+1(888) 690-3779"
                className={style.heading2}
              >
                <p style={{ textDecoration: "none" }}>+1(888) 690-3779</p>
              </a>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div className={style.box}>
              <IoMdMail className={style.icon} />
            </div>

            <div>
              <p className={style.heading1}>MAIL FOR SUPPORT</p>
              <a
                style={{ textDecoration: "none", fontSize: "13px" }}
                href="mailto:mstechssolution.Contact@mssolution.org"
                className={style.heading2}
              >
                mstechssolution.Contact@mssolution.org
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
