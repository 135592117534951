import React from "react";
import Carousel from "react-multi-carousel";
import classNames from "classnames";
import "react-multi-carousel/lib/styles.css";
import style from "./homeslider.module.css";
import SWAT from "../../assests/Zulfi.jpeg";
import HUNZA from "../../assests/Tahmina.jpeg";
import SKARDU from "../../assests/touseef.jpeg";
import UMBRELLA from "../../assests/Sarfraz.jpeg";
import NATHIA from "../../assests/Capture.PNG";
import SALT from "../../assests/bilalAzam.png";
import FAIRY from "../../assests/Userr2.jpeg";
import GILGIT from "../../assests/bilalAzam.png";
import PishinValley from "../../assests/Userr2.jpeg";

import { Laptop } from "@mui/icons-material";

const responsive = {
  lgdesktop: {
    breakpoint: { max: 3000, min: 1441 },
    items: 2,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1041 },
    items: 2,
    slidesToSlide: 1,
  },
  Laptop: {
    breakpoint: { max: 1040, min: 769 },
    items: 2,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 481 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 480, min: 320 },
    items: 1,
    slidesToSlide: 1,
  },
};

const sliderItems = [
  {
    imageUrl: "https://internetreseller.us/assets/img/project/prooject-1.png",
    Heading: "Cable Service Providers",
    Heading2: "Case Study, Growth",
    text: "As a leading cable provider, we offer access to diverse TV channels and entertainment options. Enjoy crystal-clear picture quality and a wide range of programming  tailored to your interests. Choose from various packages with different channels and features to ensure maximum value. Whether you're a sports enthusiast, a movie lover,  or seeking family-friendly content, We provides seamless cable services to suit your needs.",
  },
  {
    imageUrl: "https://internetreseller.us/assets/img/project/prooject-2.png",
    Heading: "Wireless Internet Service",
    Heading2: "Case Study, Growth",
    text: "Here's a rephrased version of the content with the same length:  When it comes to wireless internet, we provide reliable, high-speed connectivity  without cables. Our wireless services offer seamless browsing and streaming  experiences. We have various plans to suit different needs and budgets. Our  dedicated support team is always available to assist with any queries or issues.  Choose us today and enjoy the freedom of wireless connectivity.",
  },
  {
    imageUrl: "https://internetreseller.us/assets/img/project/prooject-3.png",
    Heading: "Internet Service Providers",
    Heading2: "Case Study, Growth",
    text: "If you need high-speed internet,We are your go-to provider. As a leading market provider, we ensure reliable, fast connections for seamless browsing and streaming.  With diverse plans, you can pick one that fits your needs and budget. Enjoy perks  like free installation and our dedicated support team ready to help anytime. Choose  us now for lightning-fast services that meet all your requirements.",
  },
];

const HomeSlider = () => {
  return (
    <div className="parent" style={{ marginBottom: "24px" }}>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        dotListClass="custom-dot-list-style"
      >
        {sliderItems.map((item, index) => (
          <div className={style.slider} key={index}>
            <img src={item.imageUrl} className={style.imgs} />
            <div className={style.overlay}>
              <h1 className={classNames(style.heading)}>{item.Heading}</h1>
              <h3 style={{ textAlign: "center" }}>{item.Heading2}</h3>

              <p className={classNames(style.text)}>{item.text}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default HomeSlider;
