// src/pages/HomePage.js
import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import styles from "./home.module.css";
import AdBar from "../Components/AdsBar";
import logo from "../assests/logo .png";
import HomeSlider from "../Components/HomeSilder";
import { IoClose } from "react-icons/io5";

const HomePage = () => {
  useEffect(() => {
    document.title = "Spectrum Trusted Reseller | Home";
    window.scrollTo(0, 0);
  }, []);

  const [isPopoutVisible, setIsPopoutVisible] = useState(true);

  const handleClosePopout = () => {
    setIsPopoutVisible(false); // Hide only the popouter div
  };
  return (
    <div className={styles.homepage}>
      <AdBar />
      <NavBar />
      <div>
        {isPopoutVisible && ( // Conditionally render the popouter div
          <div className={styles.popouter}>
            <div
              style={{
                background: "#003057",
                color: "white",
                textAlign: "center",
                padding: "15px",
                boxSizing: "border-box",
                width: "100%",
                borderRadius: "8px 8px 0 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Call Now +1(888) 690-3779
                </h2>
              </a>
              {/* <IoClose className={styles.close} onClick={handleClosePopout} /> */}
            </div>
            <img src={logo} className={styles.popImg} alt="Logo" />
            <div>
              <h1> Call to Pay Your Bill </h1>
            </div>
            <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
              <button
                style={{
                  marginTop: "16px",
                  background: "#003057",
                  border: "#003057",
                  width: "290px",
                }}
                className={styles.btnCall}
              >
                +1(888) 690-3779
              </button>
            </a>
            <div
              style={{
                background: "#003057",
                color: "white",
                textAlign: "center",
                boxSizing: "border-box",
                padding: "15px",
                width: "100%",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <h3 style={{ color: "white" }}>Call Now +1(888) 690-3779</h3>
              </a>
            </div>
          </div>
        )}
      </div>
      <section className={styles.hero}>
        <div className={styles.overlay}>
          <div className={styles.w60}>
            {/* Overlay */}
            <h1 className={styles.heroTitle}>Get Best Internet services</h1>
            <p className={styles.heroSubtitle}>
              We Provide The Best Broadband Internet Service
            </p>
            <p className={styles.herotext}>
              Helping you to the best internet and cable TV plans near you.
            </p>
            <div className={styles.flxx}>
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <button className={styles.btnn22}> CALL US</button>
              </a>
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <button className={styles.btnn22}> +1(888) 690-3779</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className={styles.outer}>
        <div className={styles.containter}>
          <div style={{ marginTop: "40px" }} className={styles.flxx}>
            <div className={styles.w50}>
              <img
                src="https://internetreseller.us/assets/img/images/about-img-1.png"
                className={styles.aboutImg}
              />
            </div>
            <div className={styles.w50}>
              <h3 style={{ color: "#1E6EE7", marginBottom: "24px" }}>
                About Our Company
              </h3>
              <h1>Get Best Internet services Over The Phone</h1>
              <p
                style={{ fontSize: "17px ", color: "#666", marginTop: "24px" }}
              >
                We pride ourselves on being the leading provider of broadband
                and internet services, offering unparalleled connectivity
                solutions tailored to meet your needs. Our commitment to
                delivering exceptional service
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                  marginTop: "30px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "13px" }}
                >
                  <div className={styles.aboutBox}>
                    <img src="https://internetreseller.us/assets/img/icon/about-icon-1.png" />
                  </div>
                  <h4>Secure Payment Method</h4>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "13px" }}
                >
                  <div className={styles.aboutBox}>
                    <img src="https://internetreseller.us/assets/img/icon/about-icon-2.png" />
                  </div>
                  <h4>24/7 | Support For Clients</h4>
                </div>
              </div>
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <button className={styles.btnn}>
                  {" "}
                  Call Us +1(888) 690-3779
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Counter}>
        <div className={styles.containter}>
          <div className={styles.flxx} style={{ gap: "60px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <div className={styles.InnerCount}>
                <img src="https://internetreseller.us/assets/img/icon/counter-icon-1.png" />
              </div>
              <div>
                <h1>57 </h1>
                <h4 style={{ marginTop: "8px" }}>Projects Done </h4>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <div className={styles.InnerCount}>
                <img src="https://internetreseller.us/assets/img/icon/counter-icon-2.png" />
              </div>
              <div>
                <h1>17 </h1>
                <h4 style={{ marginTop: "8px" }}>Media Activities</h4>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <div className={styles.InnerCount}>
                <img src="https://internetreseller.us/assets/img/icon/counter-icon-3.png" />
              </div>
              <div>
                <h1>82 </h1>
                <h4 style={{ marginTop: "8px" }}>Skilled Experts Agents</h4>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <div className={styles.InnerCount}>
                <img src="https://internetreseller.us/assets/img/icon/counter-icon-4.png" />
              </div>
              <div>
                <h1>1,000 </h1>
                <h4 style={{ marginTop: "8px" }}>Happy Clients</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.serviceSection}>
        <div className={styles.containter}>
          <div className={styles.flxx}>
            <div className={styles.serviceCard}>
              <div className={styles.serviceBox}>
                <img src="https://internetreseller.us/assets/img/icon/service-icon-1.png" />
              </div>
              <h2>Internet</h2>
              <p style={{ color: "#666", marginTop: "16px" }}>
                Experience speeds reaching up to 400 Mbps via DSL, fiber-optic,
                or satellite. We offer competitive pricing and customizable
                options.
              </p>
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <button className={styles.btnCall}>CAll US</button>
              </a>
            </div>{" "}
            <div className={styles.serviceCard}>
              <div className={styles.serviceBox}>
                <img src="https://internetreseller.us/assets/img/icon/service-icon-2.png" />
              </div>
              <h2>Cable Tv</h2>
              <p style={{ color: "#666", marginTop: "16px" }}>
                Discover cable plans that suit your needs. Enjoy premium picture
                quality, flexible viewing options, and outstanding customer
                support.
              </p>
              <button className={styles.btnCall}>CAll US</button>
            </div>{" "}
            <div className={styles.serviceCard}>
              <div className={styles.serviceBox}>
                <img src="https://internetreseller.us/assets/img/icon/service-icon-3.png" />
              </div>
              <h2>Landline</h2>
              <p style={{ color: "#666", marginTop: "16px" }}>
                Connect for leisure or work with our cost-effective landline
                plans from leading USA providers, featuring clear sound and
                various options
              </p>
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <button className={styles.btnCall}>CAll US</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contentSection}>
        <div className={styles.containter}>
          <div className={styles.flxLeft}>
            <div className={styles.contentBox}>
              <h3 style={{ color: "#1E6EE7", marginBottom: "24px" }}>
                Determination of our core team
              </h3>
              <h1>Bringing the World to You: A Guide to Internet Services</h1>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <button className={styles.btncontent}>ABOUT US</button>

                <button className={styles.btncontent}>OUR MISSION</button>

                <button className={styles.btncontent}>OUR VISION</button>
              </div>
              <button className={styles.btnCall}>GET A QUOTE</button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sliderSection}>
        <div className={styles.containter}>
          <h3 style={{ color: "#1E6EE7", marginBottom: "24px" }}>
            Our Projects
          </h3>
          <h1 style={{ marginBottom: "100px" }}>Our Services Case Study</h1>
          <HomeSlider />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
