import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBar";
import styles from "./version.module.css";
import classNames from "classnames";
import logo from "../assests/logo .png";
import Footer from "../Components/Footer";
import AdBar from "../Components/AdsBar";
import FAQS from "../Components/FAQs";
import image1 from "../assests/Capture2.PNG";
import { IoClose } from "react-icons/io5";

export default function Verizon() {
  useEffect(() => {
    document.title = "Spectrum Trusted Reseller | Verizon";
    window.scrollTo(0, 0);
  });

  const [isPopoutVisible, setIsPopoutVisible] = useState(true);

  const handleClosePopout = () => {
    setIsPopoutVisible(false); // Hide only the popouter div
  };
  return (
    <div>
      <AdBar />
      <NavBar />
      <div>
        {isPopoutVisible && ( // Conditionally render the popouter div
          <div className={styles.popouter}>
            <div
              style={{
                background: "#003057",
                color: "white",
                textAlign: "center",
                padding: "15px",
                boxSizing: "border-box",
                width: "100%",
                borderRadius: "8px 8px 0 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {" "}
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Call Now +1(888) 690-3779
                </h2>
              </a>
              {/* <IoClose className={styles.close} onClick={handleClosePopout} /> */}
            </div>
            <img src={logo} className={styles.popImg} alt="Logo" />
            <div>
              <h1> Call to Pay Your Bill </h1>
            </div>
            <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
              <button
                style={{
                  marginTop: "16px",
                  background: "#003057",
                  border: "#003057",
                  width: "290px",
                }}
                className={styles.btnCall}
              >
                +1(888) 690-3779
              </button>
            </a>
            <div
              style={{
                background: "#003057",
                color: "white",
                textAlign: "center",
                boxSizing: "border-box",
                padding: "15px",
                width: "100%",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <h3 style={{ color: "white", textAlign: "center" }}>
                  Call Now +1(888) 690-3779
                </h3>
              </a>
            </div>
          </div>
        )}
      </div>
      <div className={styles.banner}>
        <h1>
          Create the Verizon Fios plans you want and only pay for what you need
        </h1>
      </div>
      <div className={styles.outer}>
        <div className={styles.containter}>
          <div className={styles.flxx}>
            <div className={styles.cardd}>
              <h2>Choose your internet plan</h2>
              <p>
                Pick from our simple options for fast, reliable home internet.
                Plans start at $35/mo. with Auto Pay & select 5G mobile plans
              </p>
            </div>
            <div className={styles.cardd}>
              <h2>Add your entertainment and save</h2>
              <p>
                Like Netflix & Max for $10/mo. Disney+, Hulu, ESPN+ for $10/mo.
                Save big on the entertainment you love​
              </p>
            </div>
          </div>
          <div className={styles.card3}>
            <h2>The price is guaranteed</h2>
            <p>No hidden fees, equipment charges or unexpected price hikes​ </p>
          </div>
          <div>
            <h1
              style={{
                color: "#1E6EE7",
                marginTop: "24px",
                textAlign: "center",
              }}
            >
              Check Verizon Fios availability in your area
            </h1>
            <p style={{ fontSize: "18px", textAlign: "center" }}>
              Find out whether Fios Home Internet, 5G Home Internet or LTE Home
              Internet is available in your area
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button className={styles.btnCall}>Call Us</button>
            </div>
          </div>
          <div className={styles.flxx}>
            <div className={styles.Cardsss}>
              <h2>200 Mbps</h2>
              <p>Stream and download movies, shows and photos.</p>
              <ul>
                <li>Per month w/ Auto Pay + taxes & equip. charges.</li>
                <li>Disney+ on us for 12 months ($6.99/mo. after)</li>
                <li>Genie HD DVR to store over 200 hours of TV</li>
              </ul>
              <h2 style={{ marginTop: "16px" }}>$39.99/per month</h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button className={styles.btnCall}>Call Us</button>
              </div>
            </div>
            <div className={styles.Cardsss}>
              <h2>Your Fios TV</h2>
              <p>
                125+ channels. Choose 5 of your favorite channels and we'll
                recommend a package based on your preferences.
              </p>
              <ul>
                <li>Per month + taxes & equip. charges.</li>
                <li>Disney+ on us for 12 months ($6.99/mo. after)</li>
                <li>Genie HD DVR to store over 200 hours of TV</li>
              </ul>
              <h2 style={{ marginTop: "16px" }}>$50.00/per month</h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button className={styles.btnCall}>Call Us</button>
              </div>
            </div>
            <div className={styles.Cardsss}>
              <h2>Home Phone</h2>
              <p>
                Get all the basics with unlimited talk, text and data. And never
                worry about overage charges again.
              </p>
              <ul>
                <li>Per Line per month. Plus taxes & fees</li>
                <li>5G access included with a 5G phone. A $10/mo value.</li>
                <li>Unlimited 4G LTE (+25 GB premium data)</li>
              </ul>
              <h2 style={{ marginTop: "16px" }}>$80.00/per month</h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button className={styles.btnCall}>Call Us</button>
              </div>
            </div>
          </div>
          <h1
            style={{
              margin: "24px 0px",
              color: "#1E6EE7",
              textAlign: "center",
            }}
          >
            More ways to save
          </h1>
          <div className={styles.flxx}>
            <div className={styles.boxxxs}>
              <img
                src="https://internetreseller.us/assets//img/verizon/HomeHub_level4Bento_tile1_d.jpeg"
                className={styles.imgesss}
              />
              <div style={{ padding: "20px" }}>
                <h1 style={{ color: "#1E6EE7", margin: "16px 0px" }}>
                  Find out if you qualify for discounted home internet
                </h1>
                <p style={{ fontSize: "22px", fontWeight: "500" }}>
                  You could save up to $20/mo with Verizon Forward
                </p>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "15px",
                    fontWeight: "500",
                  }}
                >
                  Call us for Learn more...{" "}
                </p>
              </div>
            </div>
            <div className={styles.boxxxs}>
              <img
                src="https://internetreseller.us/assets//img/verizon/HomeHub_level4Bento_tile2_d.jpeg"
                className={styles.imgesss}
              />
              <div style={{ padding: "20px" }}>
                <h1 style={{ color: "#1E6EE7", margin: "16px 0px" }}>
                  Save up to $300 a year with mobile & home
                </h1>
                <p style={{ fontSize: "22px", fontWeight: "500" }}>
                  When you add select home internet plans to select 5G mobile
                  plans.
                </p>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "15px",
                    fontWeight: "500",
                  }}
                >
                  Call us for Learn more...{" "}
                </p>
              </div>
            </div>
          </div>{" "}
          <div>
            <p
              style={{
                textAlign: "center",
                margin: "16px 0px",
                color: "#666",
                fontSize: "20px",
              }}
            >
              What We Offer
            </p>
            <h1
              style={{
                margin: "8px 0px",
                color: "#1E6EE7",
                textAlign: "center",
              }}
            >
              Why choose Verizon Home Internet?
            </h1>
          </div>
          <div className={styles.flxx}>
            <div className={styles.ccardss}>
              <h2>Simplify Your Billing with Verizon 5G</h2>
              <p>
                Keep track of your monthly expenses effortlessly with Verizon
                5G. Whether you opt for multiple 5G services or just one,
                everything comes on a single, consolidated bill. This
                streamlined approach means you spend less time sorting through
                various charges and more time enjoying your services.
              </p>
            </div>
            <div className={styles.ccardss}>
              <h2>Hassle-Free Installation by Experts</h2>
              <p>
                With Verizon 5G, you can sit back while the experts handle
                everything. Our full-service installation ensures a professional
                technician will set up your devices and connect them to the 5G
                network swiftly and effectively, saving you the trouble of
                dealing with technical details.
              </p>
            </div>
            <div className={styles.ccardss}>
              <h2>Customize Your Fios Plan with 5G Mix and Match</h2>
              <p>
                Experience flexibility and savings with our 5G Mix and Match
                plans. Tailor your 5G package to perfectly fit your household’s
                needs. Choose your desired internet speed and select the TV
                package that suits your preferences. Plus, combine fast internet
                with reliable home phone service—all in one personalized plan.
              </p>
            </div>
          </div>
          <div
            style={{ marginBottom: "30px", marginTop: "40px" }}
            className={styles.flxx}
          >
            <div className={styles.ratecard}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1 style={{ width: "400px" }}>
                  The ultimate iPhone plan, with Apple One
                </h1>
                <div style={{ width: "100px" }}>
                  <p style={{ fontSize: "22px", fontWeight: "600" }}>
                    $65 /line*
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    $9.95/line perk savings
                  </p>
                </div>
              </div>
              <div className={styles.ultimate}>
                <h1>Unlimited Ultimate</h1>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={image1} style={{ width: "50%", height: "200px" }} />
              </div>
            </div>
            <div className={styles.ratecard}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1 style={{ width: "400px" }}>
                  If you love movies, series & sports
                </h1>
                <div style={{ width: "100px" }}>
                  <p style={{ fontSize: "22px", fontWeight: "600" }}>
                    $65 /line*
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    $43.99/line perk savings
                  </p>
                </div>
              </div>
              <div className={styles.ultimate}>
                <h1>Unlimited Ultimate</h1>
              </div>
              <div
                style={{ display: "flex", alignContent: "center", gap: "20px" }}
              >
                <img
                  src="https://internetreseller.us/assets//img/verizon/perk-tile-disney-bundle-3x4-default.webp"
                  className={styles.ultimateImg2}
                />
                <img
                  src="https://internetreseller.us/assets//img/verizon/perk-tile-mobile-hotspot-3x4-default.webp"
                  className={styles.ultimateImg2}
                />
              </div>
            </div>
          </div>
          <div style={{ marginBottom: "30px" }} className={styles.flxx}>
            <div className={styles.ratecard}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1 style={{ width: "400px" }}>
                  If you love streaming & original content
                </h1>
                <div style={{ width: "100px" }}>
                  <p style={{ fontSize: "22px", fontWeight: "600" }}>
                    $55 /line*
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    $6.98/line perk savings
                  </p>
                </div>
              </div>
              <div className={styles.ultimate}>
                <h1>Unlimited Ultimate</h1>
              </div>
              <img
                src="https://internetreseller.us/assets//img/verizon/perk-tile-net-max-3x2-default.webp"
                className={styles.ultimateImg}
              />
            </div>
            <div className={styles.ratecard}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1 style={{ width: "400px" }}>
                  For easy shopping, savings & more
                </h1>
                <div style={{ width: "100px" }}>
                  <p style={{ fontSize: "22px", fontWeight: "600" }}>
                    $40 /line*
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    $2.95/line perk savings
                  </p>
                </div>
              </div>
              <div className={styles.ultimate}>
                <h1>Unlimited Ultimate</h1>
              </div>
              <img
                src="https://internetreseller.us/assets//img/verizon/perk-tile-walmart-plus-membership-3x2-default.webp"
                className={styles.ultimateImg}
              />
            </div>
          </div>
          <h1
            style={{
              color: "#1E6EE7",
              margin: "50px 0px 30px 0 ",
              textAlign: "center",
            }}
          >
            Have questions? We’ve got answers.
          </h1>
          <FAQS />
          <div className={styles.diclaimer}>
            <h1 style={{ textAlign: "center" }}>Disclaimer</h1>
            <p>
              This is not an official website of Verizon but is the Official
              Site of internetreseller.us (Marketing Partner). We may send
              updates to the mobile number/email ID registered with us. The
              content is for information purposes only and does not constitute
              an offer to avail of any service. Prices mentioned are subject to
              change without notice and properties mentioned are subject to
              availability. Images are for representation purposes only. The
              logos and images used on this website are the exclusive property
              of their respective owners and are protected under applicable
              copyright laws. We do not claim any ownership or rights to these
              materials and they are used on this website solely for
              informational purposes.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
