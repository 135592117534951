import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import styles from "./contsctus.module.css";
import classNames from "classnames";
import Footer from "../Components/Footer";
import AdBar from "../Components/AdsBar";
export default function ContactUs() {
  useEffect(() => {
    document.title = "Spectrum Trusted Reseller | Homephone";
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <AdBar />
      <NavBar />
      <div className={styles.outer}>
        <div className={styles.containter}>
          <div className={styles.backImges}></div>
          <div>
            <h1 className={styles.heading}>Best-Internet Homephone</h1>
            <p className={styles.Text}>
              At Spectrum Trusted Reseller, we value the timeless quality of
              dependable, clear, and cost-effective home phone services. In a
              world filled with digital noise, we preserve the elegance and
              dependability of traditional voice communication, skillfully
              merging classic and contemporary elements to deliver customized
              communication solutions.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Customized Connections</h2>
            <p className={styles.Text}>
              Every person, family, and business possesses distinct
              communication requirements. At Spectrum Trusted Reseller, we
              customize our home phone services to address these varied needs
              effectively. From unlimited local calling to comprehensive
              long-distance options and feature-enriched services, each plan is
              thoughtfully designed to fulfill your specific requirements.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Crystal Clear Calls</h2>
            <p className={styles.Text}>
              Step into a realm where each call is defined by impeccable sound
              quality and flawless connectivity. Spectrum Trusted Reseller.us is
              synonymous with reliability, ensuring every expression, pause, and
              sentiment is transmitted with absolute clarity.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Innovative Features</h2>
            <p className={styles.Text}>
              Our home phone packages are equipped with advanced features that
              elevate your calling experience. Voicemails transcend ordinary
              messages, becoming accessible, meaningful communications. Caller
              ID offers valuable insights, weaving technology smoothly into
              every conversation
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Effortless International Calls</h2>
            <p className={styles.Text}>
              Spectrum Trusted Reseller breaks down global barriers, making
              international calling as simple and economical as domestic calls.
              With us, the entire world is just a call away, enabling effortless
              conversations no matter the distance.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Dedicated Support</h2>
            <p className={styles.Text}>
              Our support goes beyond mere assistance—it’s a pledge. We tackle
              every inquiry and challenge with expertise and care, showcasing
              our deep commitment to surpassing your expectations.
            </p>
          </div>

          <div>
            <h2 className={styles.heading}>Explore Your Perfect Plan</h2>
            <p style={{ marginBottom: "40px" }} className={styles.Text}>
              Discover a world where tradition blends with innovation—where
              every call is marked by clarity, dependability, and advanced
              functionality. Spectrum Trusted Reseller delivers more than just a
              service; we offer an experience that not only understands but also
              reveres your communication needs, meeting them with unmatched
              elegance. Delve into our customized plans and enter a domain where
              each ring signifies exceptional quality and distinction
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
