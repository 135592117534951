import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import styles from "./privacyPolicy.module.css";
import AdBar from "../Components/AdsBar";
export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Spectrum Trusted Reseller | Privacy & Policy ";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <AdBar />
      <NavBar />

      <div className={styles.outer}>
        <div className={styles.containter}>
          <div className={styles.backImges}></div>
          <div>
            <h1 className={styles.heading}>Privacy Policy</h1>
            <p className={styles.Text}>
              Your Privacy Matters At Spectrum Trusted Reseller, safeguarding
              your privacy is our priority. This Privacy Policy elaborates on
              our commitment to protecting your personal information while you
              explore our website or utilize our Internet services. By engaging
              with our services, you agree to the terms outlined in this policy.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Information Collection</h2>
            <p className={styles.Text}>
              We gather various data types, including: Personal Data: Such as
              your name, contact, and billing information to facilitate account
              management and service provision. Usage Data: Information on your
              utilization of our services and website, including device and
              browser data, IP addresses, and interaction metrics. Cookies &
              Trackers: Technologies used to optimize user experience and gather
              engagement analytics.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Description of Services</h2>
            <p className={styles.Text}>
              Spectrum Trusted Reseller provides internet connectivity and
              related services to individuals and businesses. The specifics of
              your plan, including speed, data limits, and pricing, are outlined
              in your service agreement.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Information Utilization</h2>
            <p className={styles.Text}>
              We use the collected information to: Deliver Services: Ensure
              seamless provision, billing, and support of our Internet services.
              Enhance User Experience: Customize, innovate, and improve service
              delivery and user engagement. Marketing Communications: Provide
              updates, offers, and promotions, with an option for users to
              unsubscribe. Legal Adherence: Fulfill legal requirements and
              address disputes.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Information Disclosure</h2>
            <p className={styles.Text}>
              We pledge not to sell your personal data. We may, however,
              disclose information to: Affiliates: Third-party entities involved
              in facilitating our services. Legal Entities: Comply with legal
              directives, court orders, or governmental regulations.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Data Safety</h2>
            <p className={styles.Text}>
              We employ robust security protocols to guard your data, though we
              acknowledge that absolute online security is unattainable.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Your Options</h2>
            <p className={styles.Text}>
              You are entitled to: Review, amend, or erase your personal
              information. Unsubscribe from promotional communications. Manage
              cookies through browser configurations. Minors’ Privacy We do not
              cater to children below 13 and refrain from collecting their
              personal information.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Policy Modifications</h2>
            <p className={styles.Text}>
              We may amend this Privacy Policy periodically. Kindly review the
              "Effective Date" to be apprised of any adjustments.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Reach Out</h2>
            <p style={{ marginBottom: "40px" }} className={styles.Text}>
              For inquiries or concerns about this policy or your information,
              email us at mstechssolution.Contact@mssolution.org We appreciate
              your trust Spectrum Trusted Reseller, where your privacy is our
              esteemed commitment while we provide top-notch Internet services.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
