import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import styles from "./navBar.module.css";
import classNames from "classnames";
import logo from "../../assests/logo .png";
import { IoLocationSharp } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [isListVisible2, setIsListVisible2] = useState(false);

  const toggleList2 = () => setIsListVisible2(!isListVisible2);

  const [isListVisible, setIsListVisible] = useState(false);

  const toggleList = () => setIsListVisible(!isListVisible);

  const isActive = (path) => {
    return location.pathname === path;
  };
  const navigate = useNavigate();
  const handleGoDisclaimer = () => {
    navigate("/disclaimer");
  };
  const handleGoPrivacyPolicy = () => {
    navigate("/privacyPolicy");
  };
  const handleGoTerms = () => {
    navigate("/termsCondition");
  };
  const handleGoAboutUs = () => {
    navigate("/aboutUs");
  };
  const handleGoSpectrum = () => {
    navigate("/spectrum ");
  };
  const handleGoVerizon = () => {
    navigate("/verizon");
  };
  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarContainer}>
        <div className={styles.menuIcon} onClick={toggleMenu}>
          <i className={isOpen ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <div>
          <img src={logo} className={styles.logo} />
        </div>
        <ul className={classNames(styles.navMenu, { [styles.active]: isOpen })}>
          <li className={styles.navItem}>
            <Link
              to="/"
              className={classNames(styles.navLinks, {
                [styles.activeLink]: isActive("/"),
              })}
            >
              HOME
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link
              to="/internet"
              className={classNames(styles.navLinks, {
                [styles.activeLink]: isActive("/internet"),
              })}
            >
              INTERNET
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link
              to="/homephone"
              className={classNames(styles.navLinks, {
                [styles.activeLink]: isActive("/homephone"),
              })}
            >
              HOMEPHONE
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link
              className={classNames(styles.navLinks, {
                [styles.activeLink]: isActive([
                  "/disclaimer",
                  "/privacyPolicy",
                  "/termsCondition",
                  "/aboutUs",
                ]),
              })}
            >
              POLICIES
            </Link>
            <div className={styles.hoverText}>
              <p onClick={handleGoDisclaimer}>DISCLAIMER</p>
              <p onClick={handleGoPrivacyPolicy}>PRIVACY POlLICY</p>
              <p onClick={handleGoTerms}>TERMS & CONDITION</p>
              <p onClick={handleGoAboutUs}>ABOUT US</p>
            </div>
          </li>
          <li className={styles.navItem}>
            <Link
              // Add a path for the PROVIDERS link
              className={classNames(styles.navLinks, {
                [styles.activeLink]: isActive(["/spectrum", "/verizon"]),
              })}
            >
              PROVIDERS
            </Link>
            <div className={styles.hoverText}>
              <p onClick={handleGoSpectrum}>SPECTRUM</p>
              {/* <p onClick={handleGoVerizon}>VERIZON</p> */}
            </div>
          </li>
        </ul>

        <div>
          <div className={styles.menuIcon}>
            <FaBars
              onClick={() => setIsMenuOpen(true)}
              style={{ fontSize: "20px" }}
            />
          </div>
          {isMenuOpen && (
            <div
              className={`${styles.mobileMenu} ${
                isMenuOpen ? styles.open : ""
              }`}
            >
              <div className={styles.menuHeader}>
                <img src={logo} className={styles.logo2} />
                <IoClose
                  className={styles.Headerclose}
                  onClick={() => setIsMenuOpen(false)}
                />
              </div>
              <div className={styles.mobileMenuContent}>
                <Link
                  className={styles.menulist}
                  to="/"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Home
                </Link>
                <Link className={styles.menulist} to="/internet">
                  INTERNET
                </Link>
                <Link className={styles.menulist} to="/homephone">
                  HOMEPHONE
                </Link>
                <div className={styles.menulist}>
                  <Link style={{ textDecoration: "none", color: "#333" }}>
                    POLICIES
                  </Link>

                  <div
                    onClick={toggleList2}
                    style={{ cursor: "pointer", color: "#1E6EE7" }}
                  >
                    {isListVisible2 ? <FaMinusSquare /> : <FaPlusSquare />}
                  </div>
                </div>
                {isListVisible2 && (
                  <ul className={styles.dropdownList}>
                    <li onClick={handleGoDisclaimer}>DISCLAIMER</li>
                    <li onClick={handleGoPrivacyPolicy}>PRIVACY POlLICY</li>
                    <li onClick={handleGoTerms}>TERMS & CONDITION</li>
                    <li onClick={handleGoAboutUs}>ABOUT US</li>
                  </ul>
                )}
                <div className={styles.menulist}>
                  <Link style={{ textDecoration: "none", color: "#333" }}>
                    PROVIDERS
                  </Link>

                  <div
                    onClick={toggleList}
                    style={{ cursor: "pointer", color: "#1E6EE7" }}
                  >
                    {isListVisible ? <FaMinusSquare /> : <FaPlusSquare />}
                  </div>
                </div>
                {isListVisible && (
                  <ul className={styles.dropdownList}>
                    <li onClick={handleGoSpectrum}>SPECTRUM</li>
                    {/* <li onClick={handleGoVerizon}>VERIZON</li> */}
                  </ul>
                )}
              </div>
              <div style={{ padding: "20px" }}>
                <div className={styles.flxx}>
                  <div className={styles.boxx}>
                    <IoLocationSharp className={styles.icoon} />
                  </div>{" "}
                  <p>17 Hobson PL suit B Huntington, NY 11743</p>
                </div>
                <div className={styles.flxx}>
                  <div className={styles.boxx}>
                    <IoCallSharp className={styles.icoon} />
                  </div>{" "}
                  <a
                    style={{ textDecoration: "none" }}
                    href="tel:+1(888) 690-3779"
                  >
                    <p>+1(888) 690-3779</p>
                  </a>
                </div>
                <div className={styles.flxx}>
                  <div className={styles.boxx}>
                    <IoMdMail className={styles.icoon} />
                  </div>{" "}
                  <p>mstechssolution.Contact@mssolution.org</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}
