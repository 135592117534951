import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import styles from "./discliamber.module.css";
import AdBar from "../Components/AdsBar";
export default function Disclaimer() {
  useEffect(() => {
    document.title = "Spectrum Trusted Reseller | Disclaimer ";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <AdBar />
      <NavBar />

      <div className={styles.outer}>
        <div className={styles.containter}>
          <div className={styles.backImges}></div>
          <div>
            <h1 className={styles.heading}>Disclaimer</h1>
            <p style={{ marginBottom: "40px" }} className={styles.Text}>
              Spectrum Trusted Reseller is not responsible for any content,
              code, or any other inaccuracies. Spectrum Trusted Reseller makes
              no guarantees. In no event shall Spectrum Trusted Reseller be
              liable for any special, direct, indirect, consequential, or
              incidental damages or any damages whether in an action of
              contract, negligence or other tort arising out of or in connection
              with the use of the Service or the contents of the Service.
              Spectrum Trusted Reseller reserves the right to make additions,
              deletions, or modification to the contents of the Service at any
              time without prior notice. The Spectrum Trusted Reseller Service
              and its content are provided "as is" and "as available" without
              any warranty or representations of any kind, either express or
              implied. Spectrum Trusted Reseller is a distributor and not a
              publisher of content provided by third parties; as such, Spectrum
              Trusted Reseller exercises no editorial control over such content
              and makes no warranty or representation as to the accuracy,
              reliability, or currency of any information, content, services, or
              merchandise provided or accessible through the Spectrum Trusted
              Reseller Service. Without limiting the foregoing, Spectrum Trusted
              Reseller specifically disclaims all warranties and representations
              in any content transmitted on or in connection with the Spectrum
              Trusted Reseller Service or on sites that may appear as links on
              the Spectrum Trusted Reseller Service, or in the products provided
              as part of or in connection with the Spectrum Trusted Reseller
              Service, including without limitation any warranties of
              merchantability, fitness for a particular purpose, or
              non-infringement of third-party rights. No oral advice or written
              information given by Spectrum Trusted Reseller, nor its
              affiliates, employees, officers, directors, agents, or the like
              shall create a warranty. Pricing and availability information is
              subject to change without notice. Without limiting the foregoing,
              Spectrum Trusted Reseller does not warrant that the Spectrum
              Trusted Reseller Service will be uninterrupted, uncorrupted,
              timely, or error-free.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
