import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import styles from ".//internet.module.css";
import AdBar from "../Components/AdsBar";
export default function Internet() {
  useEffect(() => {
    document.title = "Spectrum Trusted Reseller | Internet ";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div>
        <AdBar />
        <NavBar />
        <div className={styles.outer}>
          <div className={styles.containter}>
            <div className={styles.backImges}></div>
            <div>
              <h1 className={styles.heading}>Fiber-Optic Excellence </h1>
              <p className={styles.Text}>
                Step into a world where speed meets reliability and innovation
                aligns with quality at Spectrum Trusted Reseller your premier
                choice for outstanding fiber optic internet plans. Enjoy
                ultra-fast speeds up to several Gbps, meticulously tailored to
                meet your specific needs.
              </p>
            </div>
            <div>
              <h2 className={styles.heading}>Custom Fiber Optic Solutions</h2>
              <p className={styles.Text}>
                At Spectrum Trusted Reseller, we prioritize a customized
                internet experience. Our plans are designed with precision,
                incorporating the latest technology and personalization to
                enhance all aspects of your digital lifestyle. Whether for
                gaming, streaming, working, or browsing, our connections are
                crafted to fit your unique requirements.
              </p>
            </div>
            <div>
              <h2 className={styles.heading}>
                Unmatched Speed and Performance
              </h2>
              <p className={styles.Text}>
                Experience the internet at the speed of light with our advanced
                fiber optic technology. Delight in consistent speeds of up to
                1Gbps, enabling swift downloads, uploads, and seamless
                streaming. With Spectrum Trusted Reseller, buffering and delays
                are things of the past.
              </p>
            </div>
            <div>
              <h2 className={styles.heading}>Nationwide Coverage</h2>
              <p className={styles.Text}>
                Spectrum Trusted Reseller ensures that top-tier connectivity
                reaches every corner of the country. Our extensive network
                provides unparalleled speed and reliability across both urban
                and rural areas.
              </p>
            </div>
            <div>
              <h2 className={styles.heading}>Personalized Phone Plans:</h2>
              <p className={styles.Text}>
                At Spectrum Trusted Reseller, we recognize that communication
                needs are as diverse as personalities. Our phone plans are
                tailored to match your unique communication needs, ensuring
                every call is a step into a world where clarity meets
                convenience.
              </p>
            </div>
            <div>
              <h2 className={styles.heading}>Enhanced Security Connections</h2>
              <p className={styles.Text}>
                At Spectrum Trusted Reseller, securing your data is paramount.
                Each connection is protected with stringent security measures,
                ensuring your digital interactions remain private and safe.
              </p>
            </div>
            <div>
              <h2 className={styles.heading}>Exceptional Customer Support</h2>
              <p className={styles.Text}>
                Our coverage knows no bounds. Spectrum Trusted Reseller is
                wherever you are, ensuring that top-notch internet, cable, and
                phone services are not just accessible but a standard feature of
                every American home.
              </p>
            </div>

            <div>
              <h2 className={styles.heading}>
                Join the Spectrum Trusted Reseller Experience
              </h2>
              <p style={{ marginBottom: "40px" }} className={styles.Text}>
                Embark on a journey to exceptional speed, impeccable
                reliability, and personalized internet plans with Spectrum
                Trusted Reseller Dive into a digital experience where 1Gbps
                speed is the norm, and each plan is a gateway to technological
                excellence and innovation. Discover your tailored fiber optic
                solution, where speed, security, and quality are at the
                forefront, only at internet
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
