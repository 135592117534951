import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import styles from "./aboutUs.module.css";
import classNames from "classnames";
import Footer from "../Components/Footer";
import AdBar from "../Components/AdsBar";
export default function AboutUs() {
  useEffect(() => {
    document.title = "Spectrum Trusted Reseller | About Us";
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <AdBar />
      <NavBar />
      <div className={styles.outer}>
        <div className={styles.containter}>
          <div className={styles.backImges}></div>
          <div>
            <h1 className={styles.heading}>About Us </h1>
            <p className={styles.Text}>
              Welcome to Spectrum Trusted Reseller The website is dedicated to
              providing information and resources about various internet plans
              available to consumers. It aims to assist individuals in making
              informed decisions by comparing different internet service
              providers, plans, pricing, and features. The company through this
              platform, seeks to simplify the process of choosing the most
              suitable internet plan by offering comprehensive and user-friendly
              guides and comparisons.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Unleashing Digital Potentials</h2>
            <p className={styles.Text}>
              Our ethos at Spectrum Trusted Reseller is deeply rooted in the
              unwavering commitment to bridging the divide between your current
              digital experience and the unexplored horizons of possibilities.
              We are not just offering services; we are crafting pathways where
              technology, innovation, and everyday living converge, ensuring
              every click, every call, and every stream is a journey into a
              world of infinite possibilities. Your Perfect Digital Companion
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>High-Speed Internet:</h2>
            <p className={styles.Text}>
              With Spectrum Trusted Reseller, you're connected to an ecosystem
              of internet speeds that are not just phenomenal but consistently
              stable. Our cutting-edge technology ensures that your digital
              interactions are uninterrupted, offering a browsing experience
              that’s as swift as it is reliable.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Crystal-Clear Cable Services:</h2>
            <p className={styles.Text}>
              We offer a cinematic experience right in your living room. Our
              cable services bring you a diverse array of channels, each
              delivering crisp, clear, and high-definition visuals, thanks to
              our state-of-the-art technology and expert technicians.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Personalized Phone Plans:</h2>
            <p className={styles.Text}>
              At Spectrum Trusted Reseller, we recognize that communication
              needs are as diverse as personalities. Our phone plans are
              tailored to match your unique communication needs, ensuring every
              call is a step into a world where clarity meets convenience.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Proactive Support:</h2>
            <p className={styles.Text}>
              We are reimagining customer service, offering support that’s not
              just available but anticipatory. Our team is always ahead,
              ensuring that your needs are met with solutions that are swift,
              efficient, and tailored.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Nationwide Reach:</h2>
            <p className={styles.Text}>
              Our coverage knows no bounds. Spectrum Trusted Reseller is
              wherever you are, ensuring that top-notch internet, cable, and
              phone services are not just accessible but a standard feature of
              every American home.
            </p>
          </div>

          <div>
            <h2 className={styles.heading}>Committed to Innovation</h2>
            <p style={{ marginBottom: "40px" }} className={styles.Text}>
              At Spectrum Trusted Reseller, innovation is not just a buzzword
              but our operational mantra. We are constantly evolving, tapping
              into emerging technologies and trends to ensure that your digital
              experience is not just current but futuristic. Our team of experts
              is dedicated to pushing the boundaries, turning every challenge
              into an opportunity for enhancement and every interaction into a
              narrative of unending possibilities. Step into the Spectrum
              Trusted Reseller Epoch Your journey into a world where every
              digital interaction is an exploration of possibilities begins with
              Spectrum Trusted Reseller. We’re not just your service provider;
              we are your partner in a digital odyssey, ensuring every moment
              online is an experience of speed, clarity, and innovation. Welcome
              to Spectrum Trusted Reseller – where every connection is an
              entrance into a world of digital wonders, and every subscriber is
              a valued member of a community where the future of internet
              connectivity is not just envisioned but experienced. Your grand
              digital adventure awaits!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
