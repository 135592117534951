import React from "react";
import styles from "./footer.module.css";
import { FaArrowRight } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerouter}>
        <h1 style={{ textAlign: "center" }}>Disclaimer</h1>
        <p
          style={{
            textAlign: "center",
            marginTop: "16px",
            fontSize: "20px",
            padding: "20px",
          }}
        >
          This is not an official website of Spectrum, Verizon, CenturyLink,
          AT&T, Frontier and Xfinity or any Telecommunication Service Provider,
          but is the Official Site of Spectrum Trusted Reseller (Marketing
          Partner). We may send updates to the mobile number/email ID registered
          with us. The content is for information purposes only and does not
          constitute an offer to avail of any service. Prices mentioned are
          subject to change without notice and properties mentioned are subject
          to availability. Images are for representation purposes only. The
          logos and images used on this website are the exclusive property of
          their respective owners and are protected under applicable copyright
          laws. Spectrum Trusted Reseller does not claim any ownership or rights
          to these materials and they are used on this website solely for
          informational purposes.
        </p>

        <div className={styles.footerContainer}>
          <div className={styles.footerSection}>
            <h1 style={{ fontSize: "35px" }}>Spectrum Trusted Reseller</h1>
            <ul style={{ listStyle: "none", fontSize: "18px" }}>
              <li style={{ marginTop: "24px" }}>
                17 Hobson PL suit B Huntington, NY 11743
              </li>
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <li style={{ marginTop: "10px", color: "white" }}>
                  +1(888) 690-3779
                </li>
              </a>
            </ul>
          </div>
          <div className={styles.footerSection}>
            <h2>Quick Links</h2>
            <ul className={styles.footerLinks}>
              <li>
                <FaArrowRight />
                <a href="/aboutUs">About Us</a>
              </li>
              <li>
                <FaArrowRight />

                <a href="/disclaimer">Disclaimer</a>
              </li>
              <li>
                <FaArrowRight />

                <a href="/privacyPolicy">Privacy policy</a>
              </li>
              <li>
                <FaArrowRight />

                <a href="/termsCondition">Terms And Condition</a>
              </li>
            </ul>
          </div>

          <div className={styles.footerSection}>
            <h2 style={{ marginBottom: "24px" }}>Our Newslette</h2>
            <p style={{ fontSize: "20px" }}>
              Sign up to Private's weekly newsletter to get the latest updates.
            </p>
            <div className={styles.inputFlx}>
              <input placeholder="Email Address"></input>
              <button className={styles.SubscribeBTN}>Subscribe</button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={styles.bottomOuter}>
          <p>&copy; 2024 Spectrum Trusted Reseller. All rights reserved.</p>
          <div className={styles.flxx}>
            <a
              href="/termsCondition"
              style={{
                textDecoration: "none",
                color: "white",
                cursor: "pointer",
              }}
            >
              <p>Terms & Conditions</p>
            </a>{" "}
            <a
              href="/privacyPolicy"
              style={{
                textDecoration: "none",
                color: "white",
                cursor: "pointer",
              }}
            >
              <p>Privacy Policy</p>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
