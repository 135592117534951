import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBar";
import styles from "./specturm.module.css";
import classNames from "classnames";
import Footer from "../Components/Footer";
import AdBar from "../Components/AdsBar";
import FAQS22 from "../Components/FAQs/fass";
import logo from "../assests/logo .png";
import { style } from "@mui/system";
import { IoClose } from "react-icons/io5";

export default function Spectrum() {
  useEffect(() => {
    document.title = "Spectrum Trusted Reseller | Spectrum";
    window.scrollTo(0, 0);
  });

  const [isPopoutVisible, setIsPopoutVisible] = useState(true);

  const handleClosePopout = () => {
    setIsPopoutVisible(false); // Hide only the popouter div
  };
  return (
    <div>
      <AdBar />
      <NavBar />
      <div>
        {isPopoutVisible && ( // Conditionally render the popouter div
          <div className={styles.popouter}>
            <div
              style={{
                background: "#003057",
                color: "white",
                textAlign: "center",
                padding: "15px",
                boxSizing: "border-box",
                width: "100%",
                borderRadius: "8px 8px 0 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Call Now +1(888) 690-3779
                </h2>
              </a>
              {/* <IoClose className={styles.close} onClick={handleClosePopout} /> */}
            </div>
            <img src={logo} className={styles.popImg} alt="Logo" />
            <div>
              <h1> Call to Pay Your Bill </h1>
            </div>
            <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
              <button
                style={{
                  marginTop: "16px",
                  background: "#003057",
                  border: "#003057",
                  width: "290px",
                }}
                className={styles.btnCall}
              >
                +1(888) 690-3779
              </button>
            </a>
            <div
              style={{
                background: "#003057",
                color: "white",
                textAlign: "center",
                boxSizing: "border-box",
                padding: "15px",
                width: "100%",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <h3 style={{ color: "white", textAlign: "center" }}>
                  Call Now +1(888) 690-3779
                </h3>
              </a>
            </div>
          </div>
        )}
      </div>
      <div className={styles.mainBanner}>
        <div className={styles.bannerouter}>
          <img src={logo} className={styles.logo} />
          <div className={styles.w60}>
            <h1>
              SPECTRUM ONE <br /> Fast Internet with FREE WiFi + FREE Mobile
            </h1>
            <p style={{ marginTop: "16px" }}>
              Sign up for Spectrum Internet® and get Advanced WiFi and an
              Unlimited Mobile line FREE for 12 months – all with NO contracts,
              data caps or hidden fees.
            </p>
            <h1 style={{ marginTop: "24px" }}>300 Mbps Internet</h1>
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <p
                style={{
                  fontSize: "32px",
                  fontWeight: "800",
                  marginTop: "24px",
                }}
              >
                ${" "}
              </p>
              <p style={{ fontSize: "152px", fontWeight: "800" }}>49</p>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "800",
                  marginTop: "24px",
                }}
              >
                {" "}
                99 /mo <br /> for 12 months
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <button style={{ width: "240px" }} className={styles.btnCall}>
                  SHOP INTERNET
                </button>
              </a>
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 690-3779">
                <p style={{ fontWeight: "600", fontSize: "18px" }}>
                  +1(888) 690-3779
                </p>
              </a>
            </div>
            <img
              src="https://internetreseller.us/assets/img/spectrum/fg-300freefreefree-d.avif"
              className={styles.banerImg}
            />
          </div>
        </div>
      </div>
      <div style={{ background: "#F8FDFF" }} className={styles.p200}>
        <h1 style={{ textAlign: "center" }}>
          Switch to Spectrum Mobile® for Amazing Savings
        </h1>
        <h1
          style={{
            marginTop: "24px",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          Exclusive offer for Spectrum Internet customers: Unlimited Mobile for
          $15/mo each for 12 months when you sign up for two lines. Shop Now
        </h1>
      </div>
      <div className={styles.outer}>
        <div className={styles.containter}>
          <div style={{ marginTop: "40px" }}>
            <h1 style={{ textAlign: "center" }}>
              Experience Speed and Security Like Never Before
            </h1>
            <h1
              style={{
                marginTop: "24px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Enhance your home Internet with top-tier speed and protection.
              Enjoy seamless <br /> connectivity with plans offering up to 1
              Gig.
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "40px",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "24px",
              marginTop: "60px",
              marginBottom: "60px",
            }}
          >
            <div className={styles.carrdd}>
              <div
                style={{
                  background: "#00276d",
                  padding: "24px",
                  textAlign: "center",
                }}
              >
                <h1>BASIC INTERNET</h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "32px",
                      fontWeight: "800",
                      marginTop: "24px",
                    }}
                  >
                    ${" "}
                  </p>
                  <p style={{ fontSize: "112px", fontWeight: "800" }}>49</p>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      marginTop: "24px",
                    }}
                  >
                    {" "}
                    99 /mo <br /> for 12 months
                  </p>
                </div>
              </div>
              <div style={{ padding: "30px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {" "}
                  <button style={{ width: "190px" }} className={styles.btnCall}>
                    GET 300 MBPs
                  </button>
                  <img src="https://internetreseller.us/assets/img/spectrum/Fast_Icon.svg" />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#666",
                    }}
                  >
                    Fast
                  </p>
                </div>

                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  Reliable Internet speeds up to 300 Mbps
                </p>
              </div>
            </div>
            <div className={styles.carrdd}>
              <div
                style={{
                  background: "#00276d",
                  padding: "24px",
                  textAlign: "center",
                }}
              >
                <h1>ULTRA INTERNET</h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "32px",
                      fontWeight: "800",
                      marginTop: "24px",
                    }}
                  >
                    ${" "}
                  </p>
                  <p style={{ fontSize: "112px", fontWeight: "800" }}>69</p>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      marginTop: "24px",
                    }}
                  >
                    {" "}
                    99 /mo <br /> for 24 months
                  </p>
                </div>
              </div>
              <div style={{ padding: "30px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {" "}
                  <button style={{ width: "190px" }} className={styles.btnCall}>
                    GET 500 MBPs
                  </button>
                  <img src="https://internetreseller.us/assets/img/spectrum/Fast_Icon.svg" />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#666",
                    }}
                  >
                    Faster
                  </p>
                </div>

                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  Speeds up to 500 Mbps
                </p>
                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  Two-Year Price Guarantee
                </p>
              </div>
            </div>
            <div className={styles.carrdd}>
              <div
                style={{
                  background: "#00276d",
                  padding: "24px",
                  textAlign: "center",
                }}
              >
                <h1>BASIC INTERNET</h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "32px",
                      fontWeight: "800",
                      marginTop: "24px",
                    }}
                  >
                    ${" "}
                  </p>
                  <p style={{ fontSize: "112px", fontWeight: "800" }}>79</p>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      marginTop: "24px",
                    }}
                  >
                    {" "}
                    99 /mo <br /> for 24 months
                  </p>
                </div>
              </div>
              <div style={{ padding: "30px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {" "}
                  <button style={{ width: "190px" }} className={styles.btnCall}>
                    GET 300 MBPs
                  </button>
                  <img src="https://internetreseller.us/assets/img/spectrum/Fast_Icon.svg" />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#666",
                    }}
                  >
                    Fastest
                  </p>
                </div>

                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  Twice the speed for only $10/mo more
                </p>
                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  Two-Year Price Guarantee
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Bnnerouter}>
        <h1>Enjoy Disney+ On Us</h1>
        <p
          style={{
            margin: "24px 0px",
            fontSize: "30px",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Get Disney+ Basic included with Spectrum TV® Select. Dive into endless
          entertainment. Terms Apply. <strong>Shop Now</strong>
        </p>
      </div>
      <div className={styles.outer}>
        <div className={styles.containter}>
          <div
            style={{
              display: "flex",
              marginTop: "40px",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "24px",
              marginTop: "60px",
              marginBottom: "60px",
            }}
          >
            <div className={styles.w50}>
              <img
                src="https://internetreseller.us/assets/img/spectrum/HomePage_SelfInstall_Desktop.avif"
                className={styles.w50Img}
              />
            </div>
            <div className={styles.w50}>
              <div>
                <h1 style={{ color: "#1E6EE7", marginBottom: "16px" }}>
                  SELF-INSTALL YOUR SPECTRUM INTERNET
                </h1>
                <h1 style={{ marginBottom: "16px" }}>
                  Fast and Simple Internet Setup
                </h1>
                <ul style={{ marginLeft: "20px" }}>
                  <li style={{ marginTop: "10px" }}>
                    Set up your Internet swiftly with our self-install kit
                  </li>
                  <li style={{ marginTop: "10px" }}>
                    No need for an appointment
                  </li>
                  <li style={{ marginTop: "10px" }}>
                    Pick up your self-install kit in-store or have it delivered
                    to you
                  </li>
                  <button className={styles.btnCall}>Shop</button>
                </ul>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "24px",
              marginTop: "60px",
              marginBottom: "60px",
            }}
          >
            <div className={styles.carrd}>
              <img
                src="https://internetreseller.us/assets/img/spectrum/pc-xumo-lockup.avif"
                className={styles.carrdImg}
              />
              <div style={{ padding: "20px 40px 40px 40px " }}>
                <h2 style={{ color: "#1E6EE7" }}>XUMO STREAM BOX</h2>
                <h1 style={{ marginTop: "20px" }}>$0/month for 6 months</h1>
                <h2 style={{ marginTop: "20px" }}>
                  The Xumo Stream Box combines live TV, On Demand content, and
                  popular streaming apps into one convenient device!
                </h2>
                <p style={{ fontWeight: "600", marginTop: "40px" }}>
                  Get the Xumo Stream Box
                </p>
              </div>
            </div>
            <div className={styles.carrd}>
              <img
                src="https://internetreseller.us/assets/img/spectrum/pc-couple-laptop.avif"
                className={styles.carrdImg}
              />
              <div style={{ padding: "20px 40px 40px 40px " }}>
                <h2 style={{ color: "#1E6EE7" }}>SPECTRUM INTERNET REVIEWS</h2>
                <h1 style={{ marginTop: "20px" }}>Hear from Our Customers</h1>
                <h2 style={{ marginTop: "20px" }}>
                  Discover what people are saying about Spectrum Internet
                  through genuine customer reviews.
                </h2>
                <p style={{ fontWeight: "600", marginTop: "40px" }}>
                  Explore more
                </p>
              </div>
            </div>
            <div className={styles.carrd}>
              <img
                src="https://internetreseller.us/assets/img/spectrum/image-working.avif"
                className={styles.carrdImg}
              />
              <div style={{ padding: "20px 40px 40px 40px " }}>
                <h2 style={{ color: "#1E6EE7" }}>WIFI</h2>
                <h1 style={{ marginTop: "20px" }}>Stay Connected Anytime</h1>
                <h2 style={{ marginTop: "20px" }}>
                  Learn how Spectrum provides a reliable and secure WiFi
                  experience to keep you connected.
                </h2>
                <p style={{ fontWeight: "600", marginTop: "40px" }}>
                  Check Spectrum WiFi Coverage
                </p>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "24px" }}>
            <h1 style={{ textAlign: "center" }}>
              Enjoy a Fast and Secure Connection
            </h1>
            <div
              className={styles.flxx}
              style={{ justifyContent: "center", marginTop: "40px" }}
            >
              <div className={styles.w100}>
                <img
                  src="https://internetreseller.us/assets/img/spectrum/icon-internet-mediumblue.svg"
                  className={styles.MMImage}
                />
                <h1 style={{ color: "#666", marginTop: "10px" }}>Internet</h1>
              </div>
              <div className={styles.w100}>
                <img
                  src="https://internetreseller.us/assets/img/spectrum/icon-tv-mediumblue.svg"
                  className={styles.MMImage}
                />
                <h1 style={{ color: "#666", marginTop: "10px" }}>TV</h1>
              </div>{" "}
              <div className={styles.w100}>
                <img
                  src="https://internetreseller.us/assets/img/spectrum/icon-voice-mediumblue.svg"
                  className={styles.MMImage}
                />
                <h1 style={{ color: "#666", marginTop: "10px" }}>Voice</h1>
              </div>{" "}
              <div className={styles.w100}>
                <img
                  src="https://internetreseller.us/assets/img/spectrum/icon-mobile-mediumblue.svg"
                  className={styles.MMImage}
                />
                <h1 style={{ color: "#666", marginTop: "10px" }}>Mobile</h1>
              </div>
            </div>
          </div>
          <p style={{ color: "#666", marginTop: "50px" }}>
            Internet Offer Details
            <br />
            <br />
            Limited time offer; subject to change; new residential customers
            only (no Spectrum services within past 30 days) and in good standing
            with Spectrum. Taxes and fees extra in select states. SPECTRUM
            INTERNET: Standard rates apply after promo period. Additional charge
            for installation. Speeds based on wired connection. Actual speeds
            (including wireless) vary and are not guaranteed. Gig-capable modem
            required for Gig speed. For a list of Gig-capable modems, visit our
            website.
            <br />
            Bundled Offer Details
            <br />
            <br />
            Limited time offer; subject to change; new residential customers
            only (no Spectrum services within past 30 days) and in good standing
            with Spectrum. Mobile devices excluded from offer. Taxes and fees
            extra in select states. All standard rates apply after promo period.
            Free Unlimited line reflected with up to 12 months credit on bill
            statement; limited to one per account. Must be redeemed within 30
            days. SPECTRUM INTERNET & ADVANCED WIFI: Free Advanced WiFi only
            available with activation of Unlimited line. Additional charge for
            installation. Speeds based on wired connection. Actual speeds
            (including wireless) vary and are not guaranteed. SPECTRUM MOBILE:
            Reduced speeds after 30 GB of usage per line. Tablets not eligible
            for promotion. Services subject to all applicable service terms and
            conditions, subject to change. Not available in all areas. Spectrum
            Internet and Auto Pay required. Restrictions apply.
            <br />
            Mobile Offer Details
            <br />
            <br />
            Limited time offer; subject to change; offer applies to qualified
            residential customers without any outstanding obligation to
            Spectrum. Mobile devices excluded from offer. Standard rates apply
            after promo period or if qualifying services not maintained. Both
            lines must be ordered at same time. Additional lines available at
            standard rate. Tablets not eligible for promotion. SPECTRUM MOBILE:
            Reduced speeds after 30 GB (Unlimited) and 50 GB (Unlimited Plus) of
            usage per line. Services subject to all applicable service terms and
            conditions, subject to change. Not available in all areas. Per line
            activation fee, Spectrum Internet and Auto Pay required.
            Restrictions apply.
            <br />
            Cable TV Offer Details
            <br />
            <br />
            Offer applicable to Disney+ Basic (With Ads). Must be 18 years of
            age or older to redeem offer. For existing Disney+ subscribers, this
            is an offer for an additional Disney+ subscription. This will not
            replace any existing Disney+ subscription you may already have.
            Accounts must be managed separately. You must remain on an eligible
            plan to retain your Disney+ Basic offer. If you cancel your eligible
            plan or switch to a non-eligible plan, your access to Disney+ Basic
            will end at the end of your eligible plan. One offer per eligible
            Spectrum account. Use of Disney+ Basic is subject to the Disney+
            Subscriber Agreement. ©2024 Disney and its related entities.
            <br />
            <br />
            FREE XUMO STREAM BOX: Offer limited to one box per account; must be
            redeemed at same time as qualifying Spectrum TV service. Spectrum
            Internet required. Separate subscriptions are required to view
            content through various paid applications. Standard rates apply
            after promo period or if qualifying services not maintained. Xumo
            Stream Box and all other Xumo product names, logos, slogans, and
            marks are the trademarks of Xumo or its licensors.
          </p>

          <div>
            <h1
              style={{
                color: "#1E6EE7",
                margin: "50px 0px 30px 0 ",
                textAlign: "center",
              }}
            >
              Have questions? We’ve got answers.
            </h1>
            <FAQS22 />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
