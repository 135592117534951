import React, { useState } from "react";
import styles from "./faq.module.css";
import classNames from "classnames";
import commonstyle from "./faq.module.css";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaPlus } from "react-icons/fa";

export default function FAQS22() {
  const accordionData = [
    {
      header:
        "Where is spectrum Home Internet available? And what home internet services are available to me?",
      Content:
        "It's all about the internet technology, how it's delivered to your home and where you live. Spectrum Home Internet is widely available in metro and suburban areas of New England and the Mid-Atlantic. 5G Home Internet is now in more and more cities around the country. Since LTE Home Internet uses the wireless 4G LTE network to deliver home broadband internet service, it is available in most parts of the country where Spectrum and 5G Home Internet are not available.To see which spectrum Home Internet service is available where you live, enter your address above and click check availability.",
    },
    {
      header: "What is 5G Home Internet?",
      Content:
        "5G Home Internet is ultra-fast, ultra-simple wireless home internet powered by 5G Ultra Wideband, giving you the ultra-powerful network performance and speed you need.Reliable and fast to power your whole home with lots of devices connected. No annual contracts, extra fees, data overages or equipment charges. Attractive pricing with Auto Pay and when you combine it with select 5G Mobile plans. Service price guaranteed for up to 3 years. Get up to a $500 credit to help cover any early termination fees when switching. Simple plug and play self setup, with 30 days support included. 5G Ultra Wideband is now in more and more places around the country, so more people than ever can experience Verizon’s exceptional broadband internet at home.To see if your address qualifies, enter your address above and click check availability.",
    },
    {
      header: "What is Spectrum Internet?",
      Content:
        "Spectrum Internet is a 100% fiber-optic network that delivers some of the fastest internet speeds to millions of homes in the mid-Atlantic and New England. It uses fiber optics to transmit data at the speed of light, providing a faster internet connection and powerful bandwidth for multiple devices. spectrum is one of the only internet service providers to offer matching download and upload speeds on most of our plans, which is a major advantage when you’re video chatting, gaming and sharing large files or images.",
    },
    {
      header: "What is LTE Home Internet?",
      Content:
        "LTE Home Internet is home broadband internet service that brings the spectrum 4G LTE network to your residence. It is available in certain areas where there are no other spectrum broadband options (Spectrum, 5G Home, etc.). With LTE Home Internet, you can stream video at 1080p, plus you can enjoy unlimited data with no data usage caps.",
    },
    {
      header:
        "Can spectrum help cover my early termination fee when I switch to spectrum Home Internet?",
      Content:
        "Yes! If you are stuck in a contract for home internet, switch to spectrum Home Internet and get up to $500 credit to help cover your early termination fee. If you're eligible to get a bill credit for up to $500 for an early termination fee charged by your previous home internet provider, here's how to get it. If you order Spectrum home internet, see the Spectrum Early Termination Fee instructions. If you order 5G Home, go to the 5G Home Internet bill credit page.You must submit your request within 90 days of your spectrum Home Internet setup.",
    },
    {
      header:
        "Can I transfer my current spectrum Home Internet service to a new address?",
      Content:
        "We make it easy to stay connected. Check out our page on moving spectrum Home Internet service or log into your account to get started",
    },
    {
      header: "What is spectrum Whole-Home Wi-Fi?",
      Content:
        "spectrum Whole-Home Wi-Fi includes 2 features that help you improve Wi-Fi coverage in your home: Wi-Fi Health Check in the My spectrum app, to optimize your Wi-Fi network. An optional Wi-Fi Extender* for better signal strength in hard-to-reach places. If you cancel service or change to a plan that does not include Whole-Home Wi-Fi, you must return the Verizon-owned Wi-Fi Extender or you'll be charged an unreturned equipment fee of $175.",
    },
    {
      header: "What affordable spectrum Home Internet options are available?",
      Content:
        "spectrum participated in ACP, the Affordable Connectivity Program, while the program was active prior to the last fully funded month of April 2024. spectrum now provides reduced-cost internet to eligible new or existing home Internet customers who have qualified for Lifeline, SNAP, WIC, and other assistance programs within 180 days of application or received a Federal Pell Grant within the past year with our Verizon Forward program. Qualifying customers receive a service discount on their Spectrum, 5G Home or LTE Home Internet plan price. Learn more here",
    },
  ];

  const [expanded, setExpanded] = useState(null);

  const handleAccordionChange = (panelIndex) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelIndex : null);
  };

  return (
    <div className={styles.container}>
      {accordionData.map((data, index) => (
        <div key={index} className={styles.mt8}>
          <Accordion
            className={styles.dropdown}
            expanded={expanded === index}
            onChange={handleAccordionChange(index)}
          >
            <AccordionSummary
              sx={{
                ".MuiAccordionSummary-expandIconWrapper": {
                  transform: "none",
                  transition: "none",
                },
              }}
              style={{ padding: "10px 0px" }}
              expandIcon={
                <FaPlus
                  className={classNames(styles.icon, {
                    [styles.rotate]: expanded === index,
                    [styles.colorChanged]: expanded === index,
                  })}
                />
              }
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography style={{ fontWeight: "700" }}>
                <div>
                  <p
                    className={classNames(commonstyle.fs16)}
                    style={{
                      color: expanded === index ? "blue" : "blue",
                      textAlign: "start",
                    }}
                  >
                    {data.header}
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                padding: "8px 0px 16px",
                textAlign: "start",
                textJustify: "none",
              }}
            >
              <Typography>
                <div>
                  <p style={{ fontSize: "14px", color: "#666" }}>
                    {data.Content}
                  </p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
}
